<template>
    <div class="app-container choose-subject-first">
        <div class="choose-screen-head">
            <div class="province screen-box">
                <div class="province-title box-title fw tac">招生省份：</div>
                <div class="province-content box-content">
                    <el-radio-group class="province-box box-list" v-model="query.ProvinceId" @change="handleProvince"
                        size="small">
                        <el-radio-button class="province-item box-item" v-for="(item, index) in newProvince" :key="index"
                            :label="item.ID">
                            {{ item.ProvinceName }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="screen-box">
                <div class="box-title fw tac">招生年份：</div>
                <div class="box-content">
                    <el-radio-group class="box-list" v-model="query.Year" @change="handleYear" size="small">
                        <el-radio-button class="box-item" v-for="(item, index) in newYear" :key="index" :label="item">
                            {{ item }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="subject-require" v-if="newSubject.length !== 0">
                <div class="require" v-for="(item, index) in newSubject" :key="index">
                    <div class="subject-choice">
                        <div class="single-choice s-choice screen-box" v-if="item.MaxSelectCount === 1">
                            <div class="box-title fw tac">
                                {{ item.SelectExamTitle }}科目：
                            </div>
                            <div class="box-content s-item single-item">
                                <el-radio-group v-model="singleParams[index]">
                                    <el-radio label="all">全部</el-radio>
                                    <el-radio v-for="(SItem, SIndex) in item.SelectExamSubjects" :key="SIndex"
                                        :label="SItem">{{ SItem }}
                                    </el-radio>
                                    <el-radio :label="item.SelectExamSubjects">
                                        {{ item.SelectExamSubjects.join('或') }}均可
                                    </el-radio>
                                    <el-radio v-if="item.IsNotLimit" label="-">
                                        不限选考
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                        <div class="multiple-choice s-choice screen-box" v-else>
                            <div class="box-title fw tac">
                                {{ item.SelectExamTitle }}科目：
                            </div>
                            <div class="box-content s-item multiple-item">
                                <el-checkbox-group v-model="multipleParams[index]" :max="item.MaxSelectCount"
                                    @change="handleMultiple($event, item, index)">
                                    <el-checkbox v-for="(SItem, SIndex) in item.SelectExamSubjects" :key="SIndex"
                                        :label="SItem"></el-checkbox>
                                </el-checkbox-group>
                                <span class="multiple-tips">（注：从可选科目中选择1-{{ item.MaxSelectCount }}门作为选考科目，查询对应的可报专业信息）</span>
                            </div>
                        </div>
                    </div>
                    <div class="choice-require" v-if="item.MaxSelectCount > 1">
                        <div class="screen-box s-choice">
                            <div class="box-title fw tac" :class="item.SelectExamTitle ? 'choice-require-title' : ''">
                                {{ item.SelectExamTitle }}科目要求：
                            </div>
                            <div class="box-content s-item" :class="item.SelectExamTitle ? 'choice-require-item' : ''">
                                <el-radio-group v-model="requireParams[index]">
                                    <el-radio label="all">全部</el-radio>
                                    <el-radio v-if="item.IsNotLimit" label="-">
                                        不限选考
                                    </el-radio>
                                    <el-radio v-if="multipleParams[index].length >= 1"
                                        :label="multipleParams[index].length + '+'">
                                        {{ multipleParams[index].length }}门必选
                                    </el-radio>
                                    <el-radio v-if="multipleParams[index].length > 1"
                                        :label="multipleParams[index].length + '-'">
                                        {{ multipleParams[index].length }}门任选其一
                                    </el-radio>
                                </el-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="input screen-box">
                <div class="box-title fw tac input-title">
                    查询条件：
                </div>
                <div class="box-content input-content">
                    <div class="college-input">
                        <!--<el-input placeholder="请输入院校" v-model="query.SchoolName"></el-input>-->
                        <el-autocomplete class="left-input" v-model="query.SchoolName" value-key="SchoolName"
                            :fetch-suggestions="querySearchSchool" placeholder="请输入院校" :trigger-on-focus="false"
                            @select="selectSchool"></el-autocomplete>
                    </div>
                    <div class="speciality-input">
                        <el-autocomplete class="input-item" v-model="specialityParams" value-key="Name"
                            :fetch-suggestions="querySearchSpeciality" :trigger-on-focus="specialityParams ? true : false"
                            placeholder="请输入专业名称并选择" @select="selectSpeciality">
                            <template slot-scope="{item}">
                                <span class="search-title">{{ item.Name }}</span>
                                <span class="search-tag">({{ item.EducationLevel }})</span>
                            </template>
                        </el-autocomplete>
                    </div>
                    <div class="show-speciality">
                        <el-tag v-if="showSpeciality.ID" type="danger" @close="closeSpeciality" closable>
                            {{ showSpeciality.Name + '(' + showSpeciality.EducationLevel + ')' }}
                        </el-tag>
                    </div>
                </div>
            </div>
            <div class="more-query" :class="{ 'show-more': showMoreScreen }">
                <div class="screen-box">
                    <div class="box-title fw tac">院校地区：</div>
                    <div class="box-content">
                        <el-radio-group class="box-list" v-model="query.SchoolProvinceId" size="small">
                            <el-radio-button class="box-item" :label="''">全部</el-radio-button>
                            <el-radio-button class="box-item" v-for="(item, index) in collegeAddress" :key="index"
                                :label="item.ID">
                                {{ item.Name }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="screen-box">
                    <div class="box-title fw tac">院校类别：</div>
                    <div class="box-content">
                        <el-radio-group class="box-list" v-model="query.SchoolCategoryId" size="small">
                            <el-radio-button class="box-item" :label="''">全部</el-radio-button>
                            <el-radio-button class="box-item" v-for="(item, index) in collegeCategory" :key="index"
                                :label="item.ID">
                                {{ item.CategoryName }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="screen-box">
                    <div class="box-title fw tac">院校属性：</div>
                    <div class="box-content">
                        <el-radio-group class="box-list" v-model="query.SchoolTag" size="small">
                            <el-radio-button class="box-item" :label="''">全部</el-radio-button>
                            <el-radio-button class="box-item" v-for="(item, index) in collegeTag" :key="index"
                                :label="item.Value">
                                {{ item.Name }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <div class="screen-box">
                    <div class="box-title fw tac">院校性质：</div>
                    <div class="box-content">
                        <el-radio-group class="box-list" v-model="query.SchoolNature" size="small">
                            <el-radio-button class="box-item" :label="''">全部</el-radio-button>
                            <el-radio-button class="box-item" v-for="(item, index) in collegeNature" :key="index"
                                :label="item.Value">
                                {{ item.Name }}
                            </el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div class="search-btn tac">
                <div class="more-btn">
                    <el-button type="text" v-if="!showMoreScreen" @click="showMoreScreen = true">更多筛选条件 <i
                            class="el-icon-arrow-down"></i></el-button>
                    <el-button type="text" v-else @click="showMoreScreen = false">收起条件 <i
                            class="el-icon-arrow-up"></i></el-button>
                </div>
                <el-button type="primary" style="width: 8rem;" @click="finalSearchResult">搜索</el-button>
            </div>
        </div>
        <div class="choose-main">
            <div class="result-title">
                <span class="fw tac title">查询结果：</span>
                <span>共<span class="fc">{{ queryResultMainData.Total ? queryResultMainData.Total : 0 }}</span>组专业（类），<span
                        class="fc">{{ totalItem ? totalItem.TotalNumber : 0 }}</span>条记录</span>
            </div>
            <el-table :data="queryResultTableData" border empty-text="暂无数据..."
                :header-cell-style="{ background: 'rgba(var(--themecolor),0.75)', color: '#ffffff' }" style="width: 100%">
                <el-table-column align="center" prop="SpecialtyName" label="专业名称" width="260">
                </el-table-column>
                <el-table-column align="center" width="260" label="选考要求" prop="ExamCondition">
                </el-table-column>
                <el-table-column align="center" label="院校与记录">
                    <template slot-scope="props">
                        <div>
                            {{ props.row.Number }}条记录
                            <i class="look-record" @click="lookRecordDetails(props, $event)"
                                :class="props.row.lookRecordState ? 'el-icon-remove-outline' : 'el-icon-circle-plus-outline'"></i>
                        </div>
                        <div class="record-details"
                            :class="props.row.lookRecordState ? 'record-details-show' : 'record-details-close'">
                            <el-table :data="props.row.lookRecord.Data" border v-loading="props.row.loading"
                                element-loading-background="rgba(0,0,0,.01)" element-loading-text="数据加载中..."
                                element-loading-spinner="el-icon-loading">
                                <el-table-column align="center" label="学校名称">
                                    <template slot-scope="props">
                                        <span class="school-name" @click="toSchoolDetails(props.row)">{{
                                            props.row.SchoolName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="包含专业" align="center" prop="SpecialtyNames"></el-table-column>
                                <el-table-column label="招考方向" align="center" prop="ExamDirection"></el-table-column>
                            </el-table>
                            <div class="inner-pagination">
                                <el-pagination style="text-align: center;" v-show="props.row.lookRecord.Total > 0"
                                    layout="prev, pager, next" :page-size="props.row.lookRecord.PageSize"
                                    :current-page.sync="queryResultTableData[props.$index].PageIndex"
                                    :total="props.row.lookRecord.Total" @current-change="getListInner($event, props)">
                                </el-pagination>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination style="text-align: center;" v-show="queryResultMainData.Total > 0" background
                    layout="prev, pager, next" :page-size="query.PageSize" :total="queryResultMainData.Total"
                    :current-page.sync="copyQuery.PageIndex" @current-change="turnPage">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
  
<script>
import API from '@/api/config'

import { deepClone } from '@/utils/index'

export default {
    name: "choose-subject-first",
    data() {
        return {

            //更多筛选
            showMoreScreen: false,
            //省份
            newProvince: [],
            newYear: [],
            newSubject: [],

            //院校基础查询参数
            collegeAddress: [],
            collegeCategory: [],
            collegeTag: [],
            collegeNature: [],

            //选考科目相关参数
            singleParams: ['', '', '', ''],
            multipleParams: [],
            requireParams: [],
            specialityParams: '',
            showSpeciality: {
                SpecialtyOrCategoryId: '',
                Type: '',
                EducationLevel: '',
                Name: '',
            },
            schoolParams: {
                SchoolName: '',
                PageIndex: 1,
                PageSize: 999999
            },

            //总参数
            query: {
                ProvinceId: '1',
                Year: '',
                SelectExamRuleList: [
                    {
                        SelectExamNum: '',  //接口返回的索引编号SelectExamNum
                        SelectExamSubjectList: [ //选中项的字符串

                        ],
                        SelectExamRule: ''  //参数值
                    }
                ],
                SchoolName: '', //输入的选校名称
                Specialty: null, //输入专业后 调用专业优先填报的接口：
                SchoolProvinceId: '',//通用省份ID
                SchoolCategoryId: '',
                SchoolTag: '',
                SchoolNature: '',
                PageIndex: 1,
                PageSize: 10,
            },
            //拷贝后的总参数
            copyQuery: {},

            //查询结果
            queryResultMainData: [],
            queryResultTableData: [],

            totalItem: '',
            //分页

        }
    },
    computed: {},
    watch: {

    },
    created() {

    },
    mounted() {
        this.init()
    },
    methods: {
        // 内翻页
        // get inner data
        getListInner(currentPage, prop) {
            this.queryResultTableData[prop.$index].PageIndex = currentPage

            let copyParams = deepClone(this.copyQuery)
            copyParams.PageIndex = currentPage
            copyParams.ExamCondition = prop.row.ExamCondition
            copyParams.SpecialtyName = prop.row.SpecialtyName

            this.queryResultTableData[prop.$index].loading = true

            API.Career.SelectExamPaged(copyParams).then(res => {
                this.queryResultTableData[prop.$index].lookRecord = res.data
                this.queryResultTableData[prop.$index].loading = false
            })
        },
        //翻页
        turnPage(currentPage) {
            // this.copyQuery.PageIndex = currentPage
            this.getTableData(this.copyQuery)
        },
        //跳转到院校详情
        toSchoolDetails(params) {
            let routerUrl = this.$router.resolve({
                path: "/university-detail",
                query: { id: params.SchoolId }
            })
            window.open(routerUrl.href, '_blank')
        },
        //查看记录详情
        lookRecordDetails(params, e) {

            this.queryResultTableData[params.$index].lookRecordState = !this.queryResultTableData[params.$index].lookRecordState
            if (this.queryResultTableData[params.$index].lookRecordState) {
                if (this.queryResultTableData[params.$index].lookRecord.length === 0) {
                    this.queryResultTableData[params.$index].loading = true
                    let copyParams = deepClone(this.copyQuery)
                    // delete copyParams.PageIndex
                    // delete copyParams.PageSize
                    this.queryResultTableData[params.$index].PageIndex = 1
                    this.queryResultTableData[params.$index].PageSize = 10

                    copyParams.ExamCondition = params.row.ExamCondition
                    copyParams.SpecialtyName = params.row.SpecialtyName
                    copyParams.PageIndex = 1
                    copyParams.PageSize = 10
                    API.Career.SelectExamPaged(copyParams).then(res => {
                        this.queryResultTableData[params.$index].lookRecord = res.data
                        this.queryResultTableData[params.$index].loading = false
                    }).catch(err => {
                        this.myAlert(err.message)
                        this.queryResultTableData[params.$index].loading = false
                    })
                }
            }
        },

        //最终搜索
        finalSearchResult() {
            //参数处理
            this.newSubject.map((FItem, FIndex) => {
                this.query.SelectExamRuleList[FIndex] = {}
                this.query.SelectExamRuleList[FIndex].SelectExamNum = FItem.SelectExamNum
                if (FItem.MaxSelectCount === 1) {
                    if (this.$isArray(this.singleParams[FIndex])) {
                        this.query.SelectExamRuleList[FIndex].SelectExamSubjectList = this.singleParams[FIndex]
                        this.query.SelectExamRuleList[FIndex].SelectExamRule = this.singleParams[FIndex].length + '-'
                    } else {
                        this.query.SelectExamRuleList[FIndex].SelectExamSubjectList = []
                        this.query.SelectExamRuleList[FIndex].SelectExamRule = '-'
                        if (this.singleParams[FIndex] !== '-') {
                            this.query.SelectExamRuleList[FIndex].SelectExamSubjectList.push(this.singleParams[FIndex])
                            this.query.SelectExamRuleList[FIndex].SelectExamRule = '1+'

                            if (this.singleParams[FIndex] === 'all') {
                                // this.query.SelectExamRuleList[FIndex] = {}
                                this.query.SelectExamRuleList.splice(FIndex, 1)
                            }
                        }
                    }
                } else if (FItem.MaxSelectCount > 1) {
                    this.query.SelectExamRuleList[FIndex].SelectExamSubjectList = this.multipleParams[FIndex]
                    this.query.SelectExamRuleList[FIndex].SelectExamRule = this.requireParams[FIndex]

                    if (this.query.SelectExamRuleList[FIndex].SelectExamRule === "all") {
                        this.query.SelectExamRuleList.splice(FIndex, 1)
                    }
                }
            })

            this.query.SelectExamRuleList = this.query.SelectExamRuleList.filter(item => {
                if (item) {
                    return item
                }
            })

            //拷贝已选择的参数
            this.copyQuery = deepClone(this.query)

            this.copyQuery.SchoolCategoryId = this.copyQuery.SchoolCategoryId || null
            this.copyQuery.SchoolName = this.copyQuery.SchoolName || null
            this.copyQuery.SchoolNature = this.copyQuery.SchoolNature || null
            this.copyQuery.SchoolProvinceId = this.copyQuery.SchoolProvinceId || null
            this.copyQuery.SchoolTag = this.copyQuery.SchoolTag || null

            this.getTableData(this.copyQuery)

        },
        //获取列表各项数据
        getTableData(params) {
            //获取列表数据

            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.7)'
            })
            API.Career.SelectExamStat(params).then(res => {
                this.queryResultMainData = res.data
                this.queryResultTableData = res.data.Data
                this.setLookStatus(this.queryResultTableData)
                loading.close()
            }).catch(err => {
                this.myAlert(err.message)
                loading.close()
            })
            //获取总条数
            API.Career.SelectExamStatTotalNumber(params).then(res => {
                this.totalItem = res.data
            }).catch(err => {
                this.myAlert(err.message)
            })
        },

        //给列表数据添加状态和子列表数据对象
        setLookStatus(listData) {
            return new Promise((resolve, reject) => {
                listData.forEach((item, index) => {
                    this.$set(item, 'lookRecordState', false)
                    this.$set(item, 'lookRecord', [])
                    this.$set(item, 'loading', false)
                })
                resolve()
            })
        },
        //院校查询
        querySearchSchool(querySchool, cb) {
            this.schoolParams.SchoolName = querySchool

            API.School.SchoolListBySchoolName(this.schoolParams).then(res => {
                cb(res.data.Data)
            }).catch(err => {
                this.myAlert(err.message)
            })
        },
        //院校填入
        selectSchool(item) {

        },

        //专业搜索
        querySearchSpeciality(querySpeciality, cb) {

            API.Specialty.SpecialtyOrCategorySearch({
                Name: querySpeciality,
            }).then(res => {
                if (this.specialityParams) {
                    cb(res.data)
                }
            }).catch(err => {
                this.myAlert(err.message)
            })
        },
        //专业选择
        selectSpeciality(item) {
            this.showSpeciality = item
            this.query.Specialty = {}
            this.query.Specialty.SpecialtyOrCategoryId = item.ID
            this.query.Specialty.Type = item.Type
        },
        //已选专业移除
        closeSpeciality() {
            this.showSpeciality = {}
            this.query.Specialty = null
        },
        //科目多选时，参数个数显示
        handleMultiple(params, PItem, PIndex) {
            // this.requireParams[PIndex] = '-'

            this.$set(this.requireParams, PIndex, "all")
        },

        //省份选择
        handleProvince(params) {
            this.getNewYear(params)
        },
        //年份选择
        handleYear(params) {
            this.getNewExamSubject()
        },

        //初始化查询参数
        init() {
            //省份
            API.Public.NewExamProvince().then(res => {
                this.newProvince = res.data
                this.query.ProvinceId = this.newProvince[0].ID

                this.getNewYear()

            }).catch(err => {
                this.myAlert(err.message)
            })

            //初始化基础查询参数
            this.BaseQuery()
        },

        //年份
        getNewYear() {
            API.Public.NewExamProvinceYear({
                ProvinceId: this.query.ProvinceId
            }).then(res => {
                this.newYear = res.data
                this.query.Year = res.data[0]

                this.getNewExamSubject()
            }).catch(err => {
                this.myAlert(err.message)
            })
        },
        //科目
        getNewExamSubject() {
            API.Public.NewExamSubject({
                ProvinceId: this.query.ProvinceId,
                Year: this.query.Year
            }).then(res => {
                this.newSubject = res.data

                //初始化复选框的参数
                this.newSubject.map((item, index) => {

                    // this.singleParams[index] = []
                    if (item.MaxSelectCount === 1) {
                        this.singleParams[index] = "all"
                    }
                    if (item.MaxSelectCount > 1) {
                        this.requireParams[index] = "all"
                    }
                    this.multipleParams[index] = []
                })
            }).catch(err => {
                this.myAlert(err.message)
                this.newSubject = []
            })
        },

        //基础查询参数
        BaseQuery() {
            API.School.BaseCondition().then(res => {
                this.collegeAddress = res.data.ProvinceList
                this.collegeCategory = res.data.SchoolCategoryList
                this.collegeTag = res.data.SchoolTagList
                this.collegeNature = res.data.SchoolNatureList
            }).catch(err => {
                this.myAlert(err.message)
            })
        },

        //通用错误信息提示
        myAlert(message) {
            this.$message({
                type: 'error',
                message: message,
                showClose: true,
                center: true,
            })
        }
    }
}
</script>
  
<style scoped lang="less">
// @import "~@/styles/base.scss";

.choose-subject-first {

    .choose-screen-head {

        .screen-box {
            display: flex;
            flex-wrap: wrap;
            border-top: 1px dashed #e0e0e0;

            .box-title {
                width: 10%;
                margin: .5rem;
                padding: .5rem;
            }

            .box-content {
                width: 80%;

                .box-list {

                    .box-item {}
                }
            }
        }

        .subject-require {

            .require {

                .subject-choice {

                    .single-choice {

                        .single-item {}
                    }
                }

                .choice-require {

                    .choice-require-title {
                        width: 12%;

                    }
                }

                .s-choice {
                    display: flex;
                    align-items: center;

                    .s-item {
                        margin-left: 1.5rem;
                    }

                    .choice-require-item {
                        margin-left: 0;

                    }
                }
            }

            .multiple-tips {
                font-size: 12px;
                color: rgba(170, 23, 23, 0.89);
                display: block;
            }
        }

        .input {

            .input-title {
                line-height: 2;
            }

            .input-content {
                display: flex;
                padding: 1rem 0;

                ::v-deep .el-input__inner {
                    /*border: none;*/
                    /*border-bottom: 1px solid red;*/
                    width: 20rem;

                }

                .college-input {
                    margin-right: 3rem;
                }

                .speciality-input {

                    .search-title {}

                    .search-tag {
                        font-size: 12px;
                        color: rgba(var(--themecolor),0.75);
                    }

                }

                .show-speciality {
                    padding: .2rem;
                    margin-left: 1rem;
                }
            }
        }

        .province {

            .province-title {}

            .province-content {

                .province-box {

                    .province-item {}
                }
            }
        }

        .search-btn {
            padding: 3rem 0 1rem;
            position: relative;

            .more-btn {
                position: absolute;
                top: 0;
                right: 1rem;
            }
        }

        .more-query {
            height: 0;
            position: relative;
            overflow: hidden;

        }

        .show-more {
            height: auto;
        }

        ::v-deep .el-radio-button {
            margin: .6rem;
        }

        ::v-deep .el-radio-button--small .el-radio-button__inner {
            border: 1px solid #e0e0e0;
            border-radius: 3px;
            /*box-shadow: 0 2px 3px 0 rgba(87, 113, 173, 0.2);*/
        }

        ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            box-shadow: none;
            border: 1px solid rgba(var(--themecolor),0.75);
        }
    }

    .choose-main {
        padding: 2rem 0;

        .result-title {
            display: flex;
            padding: .3rem 0;

            span {
                color: #666666;
                font-size: 16px;
            }

            .title {
                width: 10%;
                padding: 0 .5rem;
                margin: 0 .5rem;
                color: #3E4145;
            }

            .fc {
                color: rgba(var(--themecolor),1);
            }
        }

        .look-record {
            color: rgba(var(--themecolor),0.75);
            font-size: 18px;
            cursor: pointer;
            position: relative;
            top: 2px;
        }

        .record-details {
            /*transition: max-height .5s linear;*/

            .school-name {
                color: rgba(var(--themecolor),0.75);
                cursor: pointer;

                &:hover {
                    color: rgba(var(--themecolor),1);
                }
            }
        }

        .record-details-close {
            max-height: 0;
            height: 0;
        }

        .record-details-show {
            /*max-height: 200px;*/
            height: auto;
        }

        .pagination {
            margin-top: 2rem;
        }

    }
    .inner-pagination {
    margin-top: 10px;
  }

    /*公共样式开始*/
    .fw {
        font-weight: 700;
    }

    .tac {
        text-align: center;
    }

    /*公共样式结束*/
}</style>
<style lang="less">
.choose-subject-first .choose-screen-head .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  border: 1px solid rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-radio-button__inner:hover{
  color: rgba(var(--themecolor),1);
}
.choose-subject-first .el-checkbox__input.is-checked .el-checkbox__inner, .choose-subject-first .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-checkbox__input.is-checked + .el-checkbox__label{
  color: rgba(var(--themecolor),1);
}
.choose-subject-first .el-radio__input.is-checked .el-radio__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-radio__input.is-checked + .el-radio__label{
  color: rgba(var(--themecolor),1);
}
.choose-subject-first .el-checkbox__inner:hover{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-radio__inner:hover{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-radio__input.is-focus .el-radio__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-input__inner.is-active,.choose-subject-first .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-button--text{
  color: rgba(var(--themecolor),0.75);
}
.choose-subject-first .el-button--text:focus, .el-button--text:hover{
  color: rgba(var(--themecolor),1);
}
.choose-subject-first .el-button--primary {
    background-color: rgba(var(--themecolor), 0.85);
    border-color: rgba(var(--themecolor), 0.85);
}
.choose-subject-first .el-button--primary:hover {
    background-color: rgba(var(--themecolor), 1);
    border-color: rgba(var(--themecolor), 1);
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
.el-pager li.active{
    color: rgba(var(--themecolor),0.75);
}
.el-pager li:hover{
    color: rgba(var(--themecolor),0.75);
}
.el-pagination button:hover{
    color: rgba(var(--themecolor),0.75);
}
</style>