<template>
  <div class="choose-subject-second">
    <div class="head">
      <div class="top">
        <head-screen @change="handleScreen"></head-screen>
      </div>
      <div class="require">
        <div class="require-list" v-for="(item, index) in requireList" :key="index">
          <div class="name">{{ item.SelectExamTitle }}科目：</div>
          <div class="list-box">
            <el-radio-group v-if="item.MaxSelectCount <= 1" v-model="selectRequire[index]">
              <el-radio
                v-for="subItem in item.SelectExamSubjects"
                :key="subItem"
                :label="subItem"
              ></el-radio>
            </el-radio-group>
            <el-checkbox-group v-else v-model="selectRequire[index]" :min="1" :max="item.MaxSelectCount">
              <el-checkbox
                v-for="subItem in item.SelectExamSubjects"
                :key="subItem"
                :label="subItem"
              ></el-checkbox>
            </el-checkbox-group>
            <div v-show="item.MaxSelectCount > 1" class="require-tips">
              (注：从可选科目中选择1-{{ item.MaxSelectCount }}门作为选考科目，查询对应的可报专业信息)
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button class="search-button" @click="submitSearch" type="primary">搜 索</el-button>
      </div>
    </div>
    <div class="main">
      <div id="proportion" class="enroll-proportion"></div>
      <div class="main-box">
        <div class="main-box-left">
          <left-specialty
            title="学科门类推荐度：（依据可报专业数推荐）"
            :list="mainList"
            @change="handleSpecialtySelect"
          ></left-specialty>
        </div>
        <div class="main-box-right">
          <right-specialty
            :main-object="specialtyCategory"
            :query="queryProportion"
          ></right-specialty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'

import HeadScreen from '@/views/tool/chooseSubject/local-components/HeadScreen'
import LeftSpecialty from '@/views/tool/chooseSubject/local-components/LeftSpecialty'
import RightSpecialty from '@/views/tool/chooseSubject/local-components/RightSpecialty'

export default {
  components: {
    HeadScreen,
    LeftSpecialty,
    RightSpecialty
  },
  name: "ChooseSubjectSecond",
  data () {
    return {
      provinceID: '',
      year: '',

      requireList: [],
      selectRequire: [], // 初始化 让其元素类型符合绑定参数

      mainList: [],
      specialtyCategory: {}, // 选中的学科门类
      queryProportion: {},
    }
  },
  mounted () {
    this.once = function () { // 创建一个函数，直接挂到vue实例上
      this.submitSearch()
      this.once = function (){}
    }
  },
  methods: {
    // 选择专业
    handleSpecialtySelect(selectedItem){
      this.specialtyCategory = selectedItem
    },
    // 点击搜索
    submitSearch () {
      let temp = []
      this.selectRequire.forEach((item, index) => {
        let tempArray = (typeof item) === 'string' ? [item] : item
        temp[index] = {}
        temp[index].SelectExamNum = this.requireList[index].SelectExamNum
        temp[index].SelectExamSubjectList = tempArray
        temp[index].SelectExamRule = tempArray.length + '+'
      })
      this.queryProportion = {
        Year: this.year,
        ProvinceId: this.provinceID,
        SelectExamRuleList: temp
      }
      this.$nextTick(() => {
        this.getResult(this.queryProportion)
      })
    },

    // 查询结果
    getResult (query) {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0,0,0,.6)'
      })
      API.School.SelAnalysisSpecialtyOfSelectExam(query).then(res => {
        loading.close()
        this.mainList = res.data.StatisticList
        // 计算可填报比例
        let percent = Math.round(res.data.CheckNum / res.data.AllNum * 1000)

        // 初始化饼图配置
        let OEcharts = this.$echarts.init(document.getElementById('proportion'))
        let option = {
          title: {
            text: '可报考的专业比例'
          },
          tooltip: {
            show: true,
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,.5)',
            textStyle: {
              color: '#ffffff'
            },
            formatter (params) { // 返回series配置项中的对应数据
              return params.name + ':' + '<br />' + params.data.tips;
            }
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: '20',
            icon: 'circle',
          },
          series: [{
            type: 'pie',
            selectedMode: 'single',
            selectedOffset: 15,
            data: [
              {
                value: res.data.CheckNum,
                name: '可报考',
                selected: true,
                itemStyle: {
                  color: 'rgba(66, 177, 230, 1)'
                },
                tips: '占比：' + percent / 10 + '%' // 自定义字段，提示框的返回参数中使用该字段
              },
              {
                value: res.data.AllNum - res.data.CheckNum,
                name: '不可报考',
                itemStyle: {
                  color: 'rgba(255, 203, 62, 1)'
                },
                tips: '占比：' + (1000 - percent) / 10 + '%'
              },
            ],
          }],
        }
        OEcharts.setOption(option)
      })
    },

    //  省份和年份查询参数
    handleScreen (parameter) {
      this.provinceID = parameter.province
      this.year = parameter.year
      this.queryRequire().then(res => {
        this.requireList = res
        this.initializeQuery()
      })
    },

    // 通过省份和年份查询选考要求
    async queryRequire () {
      return (await API.Public.NewExamSubject({ProvinceId: this.provinceID, Year: this.year})).data
    },

    // 初始化数据结构
    initializeQuery () {
      this.selectRequire = []
      this.requireList.forEach((item, index) => {
        this.selectRequire[index] = item.MaxSelectCount <= 1 ? item.SelectExamSubjects[0] : [item.SelectExamSubjects[0]]
      })
      this.once()
    },
  }
}
</script>

<style scoped lang="less">
.choose-subject-second {
  .head {
    .require {
      &-list {
        display: flex;
        border-bottom: 1px dashed #e0e0e0;

        .name {
          width: 120px;
          padding: 9.6px;
        }

        .list-box {
          width: calc(100% - 120px);
          padding: 9.6px;

          .require-tips {
            margin-top: 10px;
            font-size: 12px;
            color: rgba(170, 23, 23, 0.89);
            display: block;
          }
        }
      }
    }

    .bottom {
      margin-top: 20px;
      text-align: center;

      .search-button {
      }
    }
  }

  .main {
    .enroll-proportion {
      width: 1250px;
      height: 300px;
      margin: auto;
    }

    &-box {
      padding: 20px 0;
      border-top: 1px dashed #efefef;
      display: flex;

      &-left{
        width: 50%;
      }
      &-right{
        width: 50%;
      }
    }
  }
}
</style>
<style lang="less">
.choose-subject-second .choose-screen-head .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  border: 1px solid rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  background-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-radio-button__inner:hover{
  color: rgba(var(--themecolor),1);
}
.choose-subject-second .el-checkbox__input.is-checked .el-checkbox__inner, .choose-subject-second .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-checkbox__input.is-checked + .el-checkbox__label{
  color: rgba(var(--themecolor),1);
}
.choose-subject-second .el-radio__input.is-checked .el-radio__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-radio__input.is-checked + .el-radio__label{
  color: rgba(var(--themecolor),1);
}
.choose-subject-second .el-checkbox__inner:hover{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-radio__inner:hover{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-radio__input.is-focus .el-radio__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-input__inner.is-active,.choose-subject-second .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-button--text{
  color: rgba(var(--themecolor),0.75);
}
.choose-subject-second .el-button--text:focus, .el-button--text:hover{
  color: rgba(var(--themecolor),1);
}
.choose-subject-second .el-button--primary {
    background-color: rgba(var(--themecolor), 0.85);
    border-color: rgba(var(--themecolor), 0.85);
}
.choose-subject-second .el-button--primary:hover {
    background-color: rgba(var(--themecolor), 1);
    border-color: rgba(var(--themecolor), 1);
}
.choose-subject-second .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  border-color: rgba(var(--themecolor),0.75);
}
</style>