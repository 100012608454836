<template>
  <div class="right-specialty">
    <div class="title">
      <span v-show="mainData.Name" class="title-heavy">{{ mainData.Name }}</span>
      各专业类的推荐度：
    </div>
    <div class="specialty-category">
      <el-table
        :data="mainData.ChildNodes"
        row-key="ID"
        border
        stripe
        header-align="center"
        :header-cell-style="{backgroundColor: 'rgba(var(--themecolor), 0.75)', color: '#ffffff'}"
      >
        <el-table-column label="推荐的专业类" align="center" prop="Name"></el-table-column>
        <el-table-column label="推荐度" align="center">
          <template slot-scope="props">
            <div class="recommend-degree" :style="{width: props.row.Percent * 100 + '%'}"></div>
          </template>
        </el-table-column>
        <el-table-column label="选课要求占比" align="center">
          <template slot-scope="props">
            <div class="look-proportion" @click="lookUp(props.row)">查看详情</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :visible.sync="showProportion"
      :title="proportionTitle"
      width="800px"
    >
      <div class="proportion-box" id="proportion_box"></div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/api/config'

export default {
  name: "RightSpecialty",
  data () {
    return {
      mainData: {},

      showProportion: false,
      proportionTitle: '',
    }
  },
  props: {
    mainObject: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    mainObject: {
      handler(newValue){
        this.mainData = newValue
      },
      deep: true
    }
  },
  mounted () {},
  methods: {

    // 查看详情比例
    lookUp(row){
      this.proportionTitle = row.Name
      let temp = {
        SpecialtyCategoryId: row.ID,
        ...this.query,
      }
      API.School.SelAnalysisSelectExamBySpecialtyCategory(temp).then(res => {
        let sum = res.data.reduce((total, current, currentIndex, array) => {
          return total + current.CountNum
        },0)

        let pie = res.data.map(item => {
          return {
            name: item.ExamCondition,
            value: item.CountNum,
            tips: this.countPercent(item.CountNum, sum)
          }
        })
        this.showProportion = true
        this.$nextTick(() => {
          this.initPie(pie)
        })

      })
    },
    // 计算各项百分比
    countPercent(item, total){
      return Math.round((item / total) * 10000) / 100 + '%'
    },
    // 饼图配置
    initPie(parameters){
      let OEcharts = this.$echarts.init(document.getElementById('proportion_box'))
      let option = {
        legend: {
          type: 'scroll',
          orient: 'vertical',
          icon: 'circle',
          right: 0
        },
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,.5)',
          textStyle: {
            color: '#ffffff'
          },
          formatter(parameter, ticket, callback){
            return parameter.data.name +'<br />' + '占比：' + parameter.data.tips
          }
        },
        series: [{
          type: 'pie',
          selectedMode: 'single',
          selectedOffset: 15,
          center: ['30%', '50%'],
          radius: ['0', '70%'],
          data: parameters
        }]
      }
      OEcharts.setOption(option)
    },
  }
}
</script>

<style scoped lang="less">
.right-specialty{
  .title{
    margin-bottom: 20px;
    &-heavy{
      color: rgba(var(--themecolor),1);
    }
  }
  .specialty-category{
    position: relative;
    .recommend-degree{
      height: 15px;
      background-color: rgba(var(--themecolor),0.55);
    }
    .look-proportion{
      cursor: pointer;
    }
  }
  .proportion-box{
    height: 300px;
  }
}

</style>
