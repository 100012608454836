<template>
  <div class="left-specialty">
    <div class="specialty-title">{{ title }}</div>
    <div class="specialty-main">
      <div class="bar-item" v-for="(item, index) in mainData" :key="index">
        <div v-show="item.Name" class="specialty-name" :class="selectedStyle(item, 'name')">{{ item.Name }}</div>
        <div
          v-show="item.Percent"
          class="specialty-percent"
          :class="selectedStyle(item, 'bar')"
          :style="{width: 500 * item.Percent + 'px'}"
          @click="handleClick(item)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSpecialty",
  data () {
    return {
      mainData: [],

      selectedItemID: ''
    }
  },
  props: {
    title: {
      type: String,
      default: '专业列表'
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    list (newValue) {
      this.mainData = newValue
      this.handleClick(newValue[0])
    }
  },
  mounted () {},
  methods: {

    // 选择bar
    handleClick(item){
      this.selectedItemID = item.ID
      this.$emit('change', item)
    },

    // 选中柱状图样式
    selectedStyle (item, type) {
      return this.selectedItemID === item.ID ? (type === 'bar' ? 'active-specialty-bar' : 'active-specialty-name') : ''
    }
  }
}
</script>

<style scoped lang="less">
.left-specialty {
  .specialty-title {}

  .specialty-main {
    .bar-item {
      width: 600px;
      margin: 50px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .specialty-name {
        min-width: 60px;
        margin-right: 20px;
        text-align: right;
        font-size: 15px;
      }

      .active-specialty-name {
        color: rgba(var(--themecolor), 1);
      }

      .specialty-percent {
        width: 500px;
        height: 15px;
        transition: width .2s linear;
        background-color: rgba(var(--themecolor), 0.55);
        cursor: pointer;
      }

      .active-specialty-bar {
        background-color: rgba(var(--themecolor), 0.75);
      }
    }
  }
}

</style>
