<template>
  <div class="head-screen">
    <div class="item-box">
      <div class="name">招生省份：</div>
      <div class="list province">
        <el-radio-group v-model="selectedProvinceID" @change="selectProvince" size="small">
          <el-radio-button
            v-for="(item, index) in provinceList"
            :key="index"
            :label="item.ID"
          >
            {{ item.ProvinceName }}
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="item-box">
      <div class="name">招生年份：</div>
      <div class="list year">
        <el-radio-group v-model="selectedYear" @change="selectYear" size="small">
          <el-radio-button
            v-for="item in yearList"
            :key="item"
            :label="item"
          ></el-radio-button>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'

export default {
  name: "HeadScreen",
  data(){
    return {
      provinceList: [], // 省份列表
      selectedProvinceID: '', // 选中省份

      yearList: [], // 年份列表
      selectedYear: '' // 选中年份
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 选择省份
    selectProvince(item) {
      this.selectedProvinceID = item
      this.getYear()
    },

    // 选择年份
    selectYear(yearItem){
      this.selectedYear = yearItem
      this.$emit('change', {province: this.selectedProvinceID,year: this.selectedYear})
    },

    // 初始化数据
    initialize(){
      this.getNewExamProvince()
    },

    // 获取新高考省份
    getNewExamProvince(){
      API.Public.NewExamProvince().then(res => {
        this.provinceList = res.data
        this.selectedProvinceID = this.provinceList[0].ID
        this.getYear()
      })
    },
    // 获取省份对应年份
    getYear(){
      API.Public.NewExamProvinceYear({ProvinceId: this.selectedProvinceID}).then(res => {
        this.yearList = res.data
        this.yearList.length > 0 && (this.selectedYear = this.yearList[0])
        this.$emit('change', {province: this.selectedProvinceID,year: this.selectedYear})
      })
    }
  }
}
</script>

<style scoped lang="less">
.head-screen{
  .item-box{
    display: flex;
    border-bottom: 1px dashed #e0e0e0;
    .name{
      width: 120px;
      padding: 9.6px;
    }
    .list{
      width: calc(100% - 120px);

      ::v-deep .el-radio-button{
        margin: 9.6px;
      }
      ::v-deep .el-radio-button__inner{
        border-left: 1px solid #E0E0E0;
        border-radius: 4px;
      }
      ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        box-shadow: none;
      }
    }
  }
}

</style>
