<template>
  <div class="app-container choose-subject-index">
    <!--<el-tabs v-model="tabOrder">
      <el-tab-pane label="按科目查找" name="first">
      </el-tab-pane>
      <el-tab-pane label="科目与专业覆盖率" name="second">2</el-tab-pane>
      <el-tab-pane label="专业类与科目组合推荐度" name="third">3</el-tab-pane>
    </el-tabs>-->
     <el-alert style="margin-bottom:10px;" title="注：1-数据来源于各省市的招生考试院；2-该数据仅为高校拟招生专业的选考科目要求，不是最终的专业计划安排；3-各位考生应以高考当年招生计划中的选考要求为准。"
    type="error" :closable="false"></el-alert>
    <el-tabs type="card">
      <el-tab-pane label="按科目查专业">
        <choose-subject-first></choose-subject-first>
      </el-tab-pane>
      <el-tab-pane label="科目与专业类覆盖率">
        <choose-subject-second></choose-subject-second>
      </el-tab-pane>
<!--      <el-tab-pane label="专业类与科目及组合推荐度">
        <choose-subject-third></choose-subject-third>
      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
  import ChooseSubjectFirst from '@/views/tool/chooseSubject/chooseSubjectFirst'
  import ChooseSubjectSecond from '@/views/tool/chooseSubject/ChooseSubjectSecond'
  // import ChooseSubjectThird from '@/views/tool/chooseSubject/ChooseSubjectThird'

  export default {
    name: "select-subject",
    components: {
      ChooseSubjectFirst,
      ChooseSubjectSecond,
      // ChooseSubjectThird,
    },
    data() {
      return {
        message: 'new exam choose subject',
        tabOrder: 'first'
      }
    }
  }
</script>

<style scoped lang="less">
  .choose-subject-index{
    /*border: 1px solid #000;*/
    padding: 10px 0 3rem ;
  }

</style>
<style lang="less">
.choose-subject-index .el-tabs__item{
  color: #666;
}
.choose-subject-index .el-tabs__item.is-active{
  color: rgba(var(--themecolor),0.75);
}
.choose-subject-index .el-tabs__item:hover{
  color: rgba(var(--themecolor),1);
}
</style>